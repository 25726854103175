<template>
  <div
    class="chart-container"
    :style="dynamicSize ? {height: '100%'} : {}"
    ref="container"
  >
    <div v-if="busy" class="loading">
      <div>
        <i class="fa fa-refresh fa-spin"></i>
      </div>
    </div>
    <img
      v-if="generateImage"
      class="chart"
      ref="chartImage"
      :src="''"
      :style="{display: $store.getters.print ? 'block' : 'none'}"
    />
    <div
      class="chart html2canvas-ignore"
      data-html2canvas-ignore
      ref="chart"
      style="height: inherit; overflow: visible"
    ></div>
  </div>
</template>
<script>
import echarts from "echarts";
import {ResizeObserver as Polyfill} from "@juggle/resize-observer";
import "@/utils/echarts-themes/telemetria";

import {cloneDeep, isEqual, debounce} from "lodash";
const ResizeObserver = window.ResizeObserver || Polyfill;

const serieFilter = ($vm, serie) => {
  if (serie?.validation) {
    serie.data = serie.data.filter(
      (sample) =>
        sample.value.length > 1 &&
        $vm.$utils.isTrue(serie.validation, {
          $value: sample.value[1]
        })
    );
  }
};

const serieEvaluation = ($vm, serie) => {
  if (serie.itemStyle.expression) {
    serie.data.forEach((sample) => {
      if (sample.value === undefined || !sample.value.length > 1) return;
      sample.initial = sample.value[1] || 0;
      sample.value[1] = $vm.$utils.eval(serie.itemStyle.expression, {
        $value: sample.initial
      });
    });
  }
};

const serieConfig = ($vm, serie) => {
  if (serie.lineStyle?.waveForm) {
    switch (serie.lineStyle.waveForm) {
      case "square":
        serie.step = "end";
        serie.smooth = false;
        break;
      case "triangle":
        serie.step = false;
        serie.smooth = false;
        break;
      case "sin":
        serie.step = false;
        serie.smooth = true;
        break;
    }
  }
};

const serieArea = ($vm, serie) => {
  if (serie.type == "line") {
    var c = $vm.$utils.hexToRgb(serie.itemStyle.color);
    if (!c) {
      c = $vm.$utils.hexToRgb($vm.$utils.rgbToHex(serie.itemStyle.color));
    }
    serie.areaStyle = {
      color: {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: `rgb(${c.r},${c.g},${c.b},0.8)`
          },
          {
            offset: 1,
            color: `rgb(${c.r},${c.g},${c.b},0.1)`
          }
        ],
        global: false
      }
    };
  }
};

export {serieFilter, serieEvaluation, serieConfig};

export default {
  name: "EquipmentHistoryChartDisplay",
  data: function () {
    return {
      busy: true,
      isOneDay: false,
      passedMidnight: false,
      tooltipFontStyle: null,
      tooltipWordWrap: false
    };
  },
  props: {
    dataset: {
      type: Array,
      required: false,
      default: null
    },
    widgetOptions: {
      type: Object,
      required: false,
      default: function () {
        return null;
      }
    },
    generateImage: {
      type: Boolean,
      required: false,
      default: false
    },
    dynamicSize: {
      type: Boolean,
      default: false
    },
    svg: {
      type: Boolean,
      default: false
    },
    disconnection: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  watch: {
    dataset: {
      handler(n, o) {
        // if (isEqual(n, o)) return;
        this._updateChart();
      },
      deep: true,
      immediate: true
    },
    widgetOptions: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        this._updateChart();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    yScale() {
      return this?.widgetOptions?.yAxis?.yScale || {};
    },
    yScaleType() {
      return this?.yScale?.type || "value";
    },
    textList() {
      if (this.yScaleType != "text_list") return null;
      let items = this?.yScale?.textList?.items || [];
      return items.sort((a, b) => a.state - b.state);
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    }
  },
  methods: {
    xAxleFormatter(value, index) {
      if (this.isOneDay) {
        // if current interval is up to one day
        let format = "LT"; // change format to hour and minute
        if (
          // if it's the first or the hour is from the next day
          index == 0 ||
          (moment(value).hours() == 0 && !this.passedMidnight)
        ) {
          format += " (l)"; // add the date to format
          // date in midnight already setted
          if (index != 0) this.passedMidnight = true;
        }
        // resets "midnight setted" when another formatting cycle begins
        if (index == 0 && this.passedMidnight) this.passedMidnight = false;
        return moment(value).format(format);
      } else return moment(value).format("L");
    },
    yAxisFormatter(value) {
      if (this.yScaleType == "text_list") {
        if (this?.textList?.length) {
          let state = this.textList.find(({state}) => state == value);
          return (state && state?.label) || "";
        }
      } else if (this.yScaleType == "expression") {
        /*
        var exp = `
          value >= 0 && value <= 1
            ? {
                "0": "Off",
                "1": "on"
              }[value]
            : ""
        `;
        */
        if (this?.yScale?.expression) {
          var entry = {
            minimum: this.yAxleProp("min"),
            maximum: this.yAxleProp("max"),
            interval: this.yAxleProp("interval"),
            value: value,
            $value: value
          };
          // var exp =
          //   this.yScale.expression.indexOf("${") >= 0
          //     ? this?.yScale?.expression
          //     : `\$\{${this?.yScale?.expression}\}`;
          return this.$utils.evaluate(entry, this.yScale.expression);
        }
      }
      return value.toLocaleString();
    },
    yAxleProp(name) {
      let yAxis = this?.widgetOptions?.yAxis || {};
      return name in yAxis ? yAxis[name] : "auto";
    },
    formatedDataValue(dataId, value) {
      let data = (this.dataEntries || {})[dataId] ?? null;
      if (data && data?.value_format_type?.id) {
        let format = this.$root.config.references.data_value_format_types.find(
          ({id}) => id == data?.value_format_type?.id || ""
        );
        if (format.format_mask == "text_list") {
          return {
            type: "text_list",
            value: this.$root.$formatter.formatedDataValue(data, value)
          };
        } else if (
          format.format_mask == "duration" &&
          data.custom_format.indexOf("%") == -1
        ) {
          return {
            type: "value",
            value: moment
              .duration(parseInt(value), data.unity_label)
              .format(data.custom_format || undefined, {
                trim: false
              })
          };
        } else if (format.format_mask == "custom") {
          return {
            type: "value",
            value: this.$utils.sprintf(data.custom_format, parseFloat(value))
          };
        } else {
          var fmt = data.unity_label
            ? `${format.format_mask} ${data.unity_label}`
            : format.format_mask;
          return {
            type: "value",
            value: this.$utils.sprintf(fmt, parseFloat(value))
          };
        }
      }
      return {
        type: "value",
        value: value
      };
    },
    fmtDateTime: function (value) {
      return value
        ? this.$dt.format(value) || moment(value).format("LTS - ll")
        : value;
    },
    sliderFormatter(value) {
      if (this.isOneDay) return moment(value).format("LTS");
      else return moment(value).format("L");
    },
    insideFormatter(value) {
      if (this.isOneDay) return moment(value).format("LTS");
      else return moment(value).format("L");
    },
    createChart() {
      const small = window.innerWidth < 768;
      const printPreview = this.$store.getters.print;
      let hasData = false;
      this.dataEntries = {};
      let series = JSON.parse(JSON.stringify(this.dataset || []));
      (this.dataset || []).filter(({data}) => {
        if (!(data || []).length || !data[0].data_id) return false;
        if (this.dataEntries[data[0].data_id]) return true;
        let DATA = this.dataList.find(
          ({id, type}) =>
            parseInt(id) === parseInt(data[0].data_id) && type !== "string"
        );
        if (DATA) {
          this.dataEntries[data[0].data_id] = DATA;
        }
      });
      this.updateContainerHeight();
      let maxPoints = 100; // max points displaying at once
      let names = {};
      series.forEach((serie) => {
        names[serie.name] = (names[serie.name] ?? -1) + 1;
      });
      series.forEach((serie, ix) => {
        if (!serie?.data?.length) return;
        hasData = true;
        if (names[serie.name] > 0) {
          serie.name = `[${ix}] ${serie.name}`;
        }
        serieConfig(this, serie);
        if (serie?.itemStyle?.showArea) {
          serieArea(this, serie);
        }
        if (
          serie?.validationReadValue == undefined ||
          serie?.validationReadValue
        ) {
          // filter raw value first, then calculate the new value
          serieFilter(this, serie);
          if (serie?.itemStyle?.expressionSlot?.chart) {
            serieEvaluation(this, serie);
          }
        } else {
          // calculate the new value, and filter the calculated value
          if (serie?.itemStyle?.expressionSlot?.chart) {
            serieEvaluation(this, serie);
          }
          serieFilter(this, serie);
        }
        if (serie.animation && (printPreview || small)) {
          serie.animation = false;
        }
      });

      // find series with bigger data length
      let maxLength = series.reduce(
          (max, {data}) => (data?.length > max ? data.length : max),
          0
        ),
        biggerThanMax = false,
        // biggerThanMax = maxLength > maxPoints,
        percentange = (maxPoints / maxLength) * 100;

      let start = biggerThanMax ? 50 - percentange / 2 : 0;
      let end = biggerThanMax ? 50 + percentange / 2 : 100;
      // let start = biggerThanMax ? 50 - percentange / 2 : 25;
      // let end = biggerThanMax ? 50 + percentange / 2 : 100;

      // specify chart configuration item and data

      let chartOptions = cloneDeep(this.widgetOptions) || {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false
          },
          formatter: null
        },
        legend: {
          type: "scroll"
        },
        toolbox: {
          show: !small && !printPreview && hasData,
          orient: "vertical",
          top: 30,
          feature: {
            restore: {},
            dataZoom: {
              title: {
                zoom: this.$t("area_zoom"),
                back: this.$t("back")
              },
              emphasis: {
                iconStyle: {
                  textFill: "#fff",
                  textBackgroundColor: "rgba(50,50,50,0.7)",
                  textPadding: 5,
                  textPosition: "left"
                }
              }
            }
          }
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: null
          }
        },
        yAxis: {
          axisLabel: {
            formatter: null
          }
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: small ? "5%" : "15%",
          top: "10%",
          containLabel: true
        },
        dataZoom: [
          {
            show: !small && !printPreview && hasData,
            type: "slider",
            labelFormatter: null,
            start,
            end
          },
          {
            show: !small && !printPreview && hasData,
            type: "inside",
            start,
            end
          }
        ],
        series: {
          type: "line",
          data: []
        }
      };

      // tooltip formatter
      if (
        "formatter" in chartOptions?.tooltip &&
        !chartOptions.tooltip.formatter
      ) {
        chartOptions.tooltip.formatter = this.tooltipFormatter;
      }
      // x Axle formatter
      if (
        "formatter" in chartOptions?.xAxis?.axisLabel &&
        !chartOptions.xAxis.axisLabel.formatter
      ) {
        chartOptions.xAxis.axisLabel = chartOptions.xAxis.axisLabel || {};
        chartOptions.xAxis.axisLabel.formatter = this.xAxleFormatter;
      }
      // y axis formatter
      if (chartOptions?.yAxis) {
        // backward compatibility issues: remove any "auto" column
        ["min", "max", "interval"].forEach((p) => {
          if ((chartOptions.yAxis || {})[p] == "auto") {
            delete chartOptions.yAxis[p];
          }
        });

        if (!chartOptions?.yAxis?.axisLabel?.formatter) {
          chartOptions.yAxis.axisLabel = chartOptions.yAxis.axisLabel || {};
          chartOptions.yAxis.axisLabel.formatter = this.yAxisFormatter;
        }
      }
      // zoom formatters
      if (chartOptions?.dataZoom?.length) {
        let slider = chartOptions.dataZoom.find((i) => i.type == "slider");
        if (slider) {
          if (slider.show && (printPreview || !hasData)) {
            slider.show = false;
          }
          if ("labelFormatter" in slider && !slider.labelFormatter) {
            slider.labelFormatter = this.sliderFormatter;
          }
          if (!slider.filterMode) {
            slider.filterMode = "none";
          }
        }
        let inside = chartOptions.dataZoom.find((i) => i.type == "inside");
        if (inside) {
          if (inside.show && printPreview) {
            inside.show = false;
          }
          if ("labelFormatter" in inside && !inside.labelFormatter) {
            inside.labelFormatter = this.sliderFormatter;
          }
          if (!inside.filterMode) {
            inside.filterMode = "none";
          }
        }
      }
      // toolbox
      if (chartOptions?.toolbox) {
        if (chartOptions.toolbox.show && (printPreview || !hasData)) {
          chartOptions.toolbox.show = false;
        }
      }
      // tooltip
      if (chartOptions?.tooltip) {
        if (
          chartOptions?.tooltip?.axisPointer?.animation &&
          (printPreview || !hasData)
        ) {
          chartOptions.tooltip.axisPointer.animation = false;
        }
      }
      // legend
      if (chartOptions?.legend) {
        if (chartOptions?.legend?.show && !hasData) {
          chartOptions.legend.show = false;
        }
      }

      chartOptions.series = series;
      chartOptions.height = "auto";

      // BEGIN DISCONNECTION
      if (this.disconnection) {
        (chartOptions.series || []).forEach((serie) => {
          serie.zlevel = 1;
          if (this.disconnection.dataLine != "trend") {
            for (var connId in this.disconnection.items || {}) {
              (this.disconnection.items[connId] || []).forEach((i) => {
                if (
                  i.ini > this.disconnection.ini &&
                  i.end < this.disconnection.end
                ) {
                  serie.data.push({
                    name: i.ini,
                    data_id: serie.data[0].data_id,
                    value: [i.ini, null]
                  });
                }
              });
            }
            serie.data = serie.data.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );
          }
        });
        for (var connId in this.disconnection.items || {}) {
          let v = [];
          (this.disconnection.items[connId] || []).forEach((i) => {
            v.push([i.ini, i.value]);
            v.push([i.end, i.value]);
            v.push([
              new Date(new Date(i.end).getTime() + 1000).toISOString(),
              null
            ]);
          });
          v.push([
            new Date(
              new Date(this.disconnection.end).getTime() - 1000
            ).toISOString(),
            null
          ]);
          var shadow = {
            name: this.$t("disconnection"),
            zlevel: 2,
            data: v,
            type: "line",
            smooth: false,
            itemStyle: {
              color: this?.disconnection?.color ?? "#FFF",
              enabled: true
            },
            areaStyle: {
              opacity: 1,
              color: this?.disconnection?.color ?? "#FFF"
            },
            showSymbol: false,
            step: false,
            symbolSize: 1,
            validation: "",
            validationReadValue: true,
            fillOpacity: 1
          };
          chartOptions.series.push(shadow);
        }
      }
      // END DISCONNECTION
      this.initOneDay(series);
      let el = this.$refs?.chart || null;
      if (el) {
        let prv = this.getChart();
        if (prv) {
          this.prvTip = {};
          prv.clear();
        }
        var chart = echarts.init(el, null, {
          renderer: this.svg ? "svg" : "canvas"
        });
        if (this.generateImage) {
          chart.on("finished", this.onFinished);
        }
        if (chartOptions.tooltip.config) {
          this.tooltipSetup(chartOptions);
        }
        chart.setOption(chartOptions);
        chart.on("legendselectchanged", (params) =>
          this.updateToolTip(params.selected)
        );
        this.$nextTick(() => {
          if (chartOptions?.tooltip?.config?.open_on_startup) {
            this.toggleToolTip();
          }
        });
      }
      this.busy = false;
    },
    initOneDay(series) {
      if (!(series || []).length) return;
      var iDt = new Date().getTime() + 1;
      var eDt = 0;
      var dts /* date string */, dt /* date */, first, last;
      series
        .filter((s) => s.type == "line" || s.type == "bar")
        .forEach(({data}) => {
          if (data.length) {
            first = data[0];
            last = data[data.length - 1];
            dts =
              (first.value && first.value.length == 2 && first.value[0]) ||
              first[0] ||
              first.name;
            dt = new Date(dts).getTime();
            if (dt <= iDt) iDt = dt;
            dts =
              (last.value && last.value.length == 2 && last.value[0]) ||
              last[0] ||
              last.name;
            dt = new Date(dts).getTime();
            if (dt >= eDt) eDt = dt;
          }
        });
      this.calcOneDay(iDt, eDt);
    },
    calcOneDay(start, end) {
      let diff = moment(end).diff(start, "hours");
      if (diff <= 24) {
        this.isOneDay = true;
      } else {
        this.isOneDay = false;
      }
    },
    onDataZoom(event) {
      // check if event was fired by slider data zoom
      let start, end;
      if (event.dataZoomId) {
        ({start, end} = getStartAndEndFrom.call(this, event.start, event.end));
      } else if (event.batch[0].dataZoomId.includes("series")) {
        // if it's from inside data zoom
        ({start, end} = getStartAndEndFrom.call(
          this,
          event.batch[0].start,
          event.batch[0].end
        ));
      } else {
        // if it's from area data zoom
        start = event.batch[0].startValue;
        end = event.batch[0].endValue;
      }

      // let diff = moment(end).diff(start, "hours");
      // if (diff <= 24) {
      //   this.isOneDay = true;
      // } else {
      //   this.isOneDay = false;
      // }
      this.calcOneDay(start, end);

      // convert start and end from percentage to absolute date value
      function getStartAndEndFrom(percentageStart, percentageEnd) {
        let firstDate = moment(
          this.dataset[0].data[this.dataset[0].data.length - 1].value[0]
        );
        let lastDate = moment(this.dataset[0].data[0].value[0]);
        let duration = moment.duration(lastDate.diff(firstDate));

        let start = firstDate
          .clone()
          .add(duration.asMilliseconds() * (percentageStart / 100), "ms");
        let end = firstDate
          .clone()
          .add(duration.asMilliseconds() * (percentageEnd / 100), "ms");
        return {start, end};
      }
    },
    getChart() {
      let el = this.$refs?.chart || null;
      return el ? echarts.getInstanceByDom(el) : null;
    },
    onFinished() {
      if (
        !(this.$refs?.container?.getBoundingClientRect()?.height || 0) ||
        !this.$refs.chart ||
        !this.$refs.chartImage
      ) {
        return;
      }
      const chart = this.getChart();
      let canvas = this.$refs.chart.getElementsByTagName("canvas")[0];
      if (chart) {
        this.$refs.chartImage.src = chart.getDataURL({
          backgroundColor: "#fff",
          type: "jpeg" // Important since png is throwing error while printing
        });
        if (this.$store.getters.print) {
          canvas.style.display = "none";
        } else {
          canvas.style.display = "block";
        }
      } else {
        this.$refs.chartImage.src = "";
        canvas.style.display = "block";
      }
    },
    updateContainerHeight() {
      if (this.$refs.container && !this.dynamicSize) {
        this.$refs.container.style.height =
          this.$parent.$el.clientHeight -
          (this.$refs.container.getBoundingClientRect().top -
            this.$parent.$el.getBoundingClientRect().top) +
          "px";
      }
    },
    panelChangeMonitor() {
      if (this._SizeAnalizer) return;

      this._height = 0; // not reactive
      this._width = 0; // not reactive
      this._skip = false; // not reactive

      this._SizeAnalizer = debounce((els) => {
        if (this.busy || this._skip) return;
        this._skip = true; // not reactive
        // let el = els && els.length ? els[0] : null;
        // let rc = el && el?.target?.getBoundingClientRect();
        let rc =
          (this.$parent && this.$parent.$el.getBoundingClientRect()) || null;
        let h = parseInt((rc && rc?.height) || 0);
        let w = parseInt((rc && rc?.width) || 0);
        if (h != this._height || w != this._width) {
          this._height = h;
          this._width = w;
          if (this._height && this._width) {
            let chart = this.getChart();
            if (chart) {
              this.updateContainerHeight();
              this.$nextTick(() => {
                chart.resize();
                this._skip = false;
              });
              return;
            }
          }
        }
        this._skip = false;
      }, 200);

      const sizeWatcher = new ResizeObserver(this._SizeAnalizer);
      let _p = this.$refs.container;
      if (this.dynamicSize) {
        _p = _p.parentElement;
      } else {
        while (!_p.classList.contains("inner-panel")) {
          _p = _p.parentElement;
          if (!_p) break;
        }
      }
      if (_p) {
        sizeWatcher.observe(_p);
      }
    },
    tooltipSetup(chartOptions) {
      const self = this;
      let tooltip = chartOptions.tooltip;
      if (!tooltip || !tooltip.config) return;
      if (tooltip.config.position_enabled) {
        // tooltip.appendToBody = true;
        const pos = (tooltip.config.type == "preset" &&
          tooltip.config.preset_value) || [
          `${tooltip.config.manual_value_x}${
            tooltip.config.manual_unit == "%" ? "%" : ""
          }`,
          `${tooltip.config.manual_value_y}${
            tooltip.config.manual_unit == "%" ? "%" : ""
          }`
        ];
        tooltip.position = function (point, params, dom, rect, size) {
          // console.log(size);
          if (pos == "top_right") {
            self.ttpos = [size.viewSize[0] - size.contentSize[0] - 15, 0];
          } else if (pos == "top_left") {
            self.ttpos = [10, 0];
          } else if (pos == "bottom_right") {
            self.ttpos = [
              size.viewSize[0] - size.contentSize[0] - 5,
              size.viewSize[1] - size.contentSize[1] - 10
            ];
          } else if (pos == "bottom_left") {
            self.ttpos = [10, size.viewSize[1] - size.contentSize[1] - 10];
          } else if (pos == "bottom_center") {
            self.ttpos = [
              size.viewSize[0] / 2 - size.contentSize[0] / 2,
              size.viewSize[1] - size.contentSize[1] - 10
            ];
          } else if (pos == "top_center") {
            self.ttpos = [size.viewSize[0] / 2 - size.contentSize[0] / 2, 0];
          } else if (typeof pos == "object") {
            self.ttpos = [
              pos[0] === "" || pos[0] === "%" ? point[0] : pos[0],
              pos[1] === "" || pos[1] === "%" ? point[1] : pos[1]
            ];
          } else {
            self.ttpos = point;
          }
          // Add any offset
          if (tooltip.config.type == "preset") {
            self.ttpos[0] += parseInt(tooltip.config.manual_value_x);
            self.ttpos[1] += parseInt(tooltip.config.manual_value_y);
          }
          return self.ttpos;
        };
        //
      }
      // font configuration
      let cssvars = null;
      if (tooltip.config.font) {
        cssvars = [];
        for (var p in tooltip.config.font) {
          cssvars.push(`${p}: ${tooltip.config.font[p]}`);
        }
      }
      if (tooltip.config.line_height) {
        cssvars = cssvars || [];
        cssvars.push(`line-height: ${tooltip.config.line_height}`);
      }
      this.$set(this, "tooltipFontStyle", (cssvars && cssvars.join(";")) || "");
      // tooltip word wrap
      this.tooltipWordWrap = tooltip.config.word_wrap;
      // keep it visible
      if (tooltip.config.keep_visible) {
        tooltip.alwaysShowContent = true;
      }
      tooltip.confine = true;
      tooltip.enterable = true;
      tooltip.extraCssText = "overflow-y: auto; max-height: 90%;";
    },
    toggleToolTip() {
      const chart = this.getChart();
      if (!chart) return;
      this.isTooltipVisible()
        ? this.hideToolTip(chart)
        : this.showToolTip(chart);
    },
    showToolTip(chart, ix) {
      chart.dispatchAction({
        type: "downplay",
        seriesIndex: ix || 0,
        dataIndex: 0
      });
      chart.dispatchAction({
        type: "highlight",
        seriesIndex: ix || 0,
        dataIndex: 0
      });
      chart.dispatchAction({
        type: "showTip",
        seriesIndex: ix || 0,
        dataIndex: 0
      });
    },
    hideToolTip(chart) {
      chart.dispatchAction({
        type: "hideTip"
      });
    },
    updateToolTip(selected) {
      let nVisible = Object.values(selected).filter((i) => i).length;
      this.prvTip = {};
      const chart = this.getChart();
      if (!chart) return;
      let opt = chart.getOption();
      if (!opt) return;
      let tooltip = opt.tooltip && opt.tooltip.length && opt.tooltip[0];
      if (tooltip && tooltip.config.keep_visible) {
        if (!nVisible) {
          tooltip.alwaysShowContent = false;
          chart.setOption(opt);
          this.hideToolTip(chart);
          return;
        }
        if (!tooltip.alwaysShowContent) {
          tooltip.alwaysShowContent = true;
          chart.setOption(opt);
        }
        if (this.isTooltipVisible()) {
          chart.dispatchAction({type: "hideTip"});
        }
        for (var i = 0; i < opt.series.length; i++) this.showToolTip(chart, i);
      }
    },
    isTooltipVisible() {
      if (!this.getChart()) return undefined;
      let el = this.$el.querySelector(".tooltip-data");
      return (
        (el && el.parentNode && el.parentNode.style.display == "block") || false
      );
    },
    tooltipFormatter(params) {
      let self = this;
      this.prvTip = this.prvTip || {};
      // let content = moment(params[0].name).format("LTS - ll");
      var disconnectionPoint = (params || []).find(
        ({seriesName, data}) =>
          seriesName == this.$t("disconnection") && data && data[1] !== null
      );
      if (disconnectionPoint) {
        this.prvTip = {};
        let dt = disconnectionPoint.value[0];
        let fmt = "";
        for (var connId in this.disconnection.items || {}) {
          (this.disconnection.items[connId] || []).forEach(
            ({ini, end, connectorName}) => {
              if (dt >= ini && dt <= end && !fmt) {
                var mI = moment(ini);
                var mE = moment(end);
                var mD = mE.diff(mI);
                fmt = `${(connectorName || "").toUpperCase()}<br/>\
              ${this.$tc("disconnected", 1)} - ${this.$t("duration")}: ${moment
                  .duration(mD / 1000, "seconds")
                  .format("hh[h]:mm[m]:ss[s]")}<br/>\
              ${mI.format("L LTS")} - ${mE.format("L LTS")}<br/>\
              `;
              }
            }
          );
        }
        return fmt || this.$tc("disconnected", 1);
      }
      let content = [];
      let found = {};
      let fmt = null;
      let itemClass = this.tooltipWordWrap
        ? "tooltip-data tooltip-data-inline"
        : "tooltip-data";
      // test simulation for a large amount of data
      // for (var i = 0; i < 40; i++)
      //   content.push(
      //     `<div class="${itemClass}" style='${this.tooltipFontStyle}'><span class='tooltip-data-icon' style="background-color:white;"></span><span class='tooltip-data-label'>Data Name ${i}: ${i}</span></div>`
      //   );
      params
        .filter(({seriesName}) => seriesName !== this.$t("disconnection"))
        .forEach((series) => {
          let value = "";
          let initial = ""; //
          if (series?.data?.data_id) {
            fmt = this.formatedDataValue(
              series?.data?.data_id,
              series.value[1]
            );
            value = fmt.value;
            if ("initial" in (series?.data || {})) {
              fmt = this.formatedDataValue(
                series?.data?.data_id,
                series?.data.initial || 0
              );
              if (fmt.type == "text_list") {
                value = series?.data.initial;
              }
              initial = fmt.value;
              if (initial == value) initial = "";
            }
          } else {
            value =
              (series?.data?.format &&
                ["text_list", "duration", "custom"].indexOf(
                  series.data.format
                ) == -1) ||
              ""
                ? self.$utils.sprintf(series.data.format, series.value[1])
                : series.value[1];
          }
          if (!content.length) {
            content.push(
              `<div class="tooltip-data" style="margin-bottom:4px;font-size:80%">${this.fmtDateTime(
                series.value[0]
              )}</div>`
            );
          }
          var serieName =
            series.seriesName.slice(0, 30) +
            (series.seriesName.length > 30 ? "..." : "");
          var fmtValue = `${value}${
            initial === "" ? "" : " (" + initial + ")"
          }`;
          content.push(
            `<div class="${itemClass}" style='${this.tooltipFontStyle}'><span class='tooltip-data-icon' style="background-color:${series.color};"></span><span class='tooltip-data-label'>${serieName}: ${fmtValue}</span></div>`
          );
          if (series?.data?.data_id) {
            found[series?.data?.data_id] = true;
            this.prvTip[series?.data?.data_id] = {
              color: series.color,
              name: serieName,
              value: fmtValue
            };
          }
        });
      for (var data_id in this.prvTip) {
        if (!found[data_id]) {
          var entry = this.prvTip[data_id];
          content.push(
            `<div class="${itemClass}" style='${this.tooltipFontStyle}'><span class='tooltip-data-icon' style="background-color:${entry.color};"></span><span class='tooltip-data-label'>${entry.name}: ${entry.value}</span></div>`
          );
        }
      }
      return content.join("");
    }
  },
  activated() {
    let chart = this.getChart();
    if (chart) {
      this.updateContainerHeight();
      chart.resize();
    }
  },
  mounted() {
    this.panelChangeMonitor();
    this._updateChart();
    this.$root.$on("chart:toggleToolTip", this.toggleToolTip);
  },
  beforeDestroy() {
    this.$root.$off("chart:toggleToolTip", this.toggleToolTip);
  },
  beforeCreate() {
    this._updateChart = debounce(() => {
      if (this.$refs.container) {
        this.createChart();
      }
    }, 500);
  }
};
</script>

<style scoped>
div.chart-container {
  width: 100%;
  height: inherit;
}

.echarts {
  margin: 0;
  width: 100%;
  height: inherit;
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-content: center;
  height: inherit;
  font-size: 20pt;
}

.loading > div {
  flex: 1 1 inherit;
  align-self: center;
}

@media screen {
  .no-screen {
    display: none;
  }
}
@media print {
  .no-print {
    display: none;
  }
}
</style>

<style>
.chart > * {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.chart > *::-webkit-scrollbar {
  display: none;
}
.tooltip-data {
  font-size: 80%;
  line-height: 1.1em;
}

.tooltip-data-inline {
  display: inline-block;
  margin-right: 12px;
}

.tooltip-data > .tooltip-data-icon {
  font-size: 85%;
  display: inline-block;
  margin-right: 4px;
  border-radius: 8px;
  width: 8px;
  height: 8px;
}

.tooltip-data > .tooltip-data-label {
  vertical-align: middle;
}
</style>
